export const barChartDefaultOptions = {
  chart: {
    type: "bar",
    height: 350,
    toolbar: { show: false }
  },
  plotOptions: {
    bar: {
      horizontal: false,
      endingShape: "rounded",
      dataLabels: {
        orientation: "horizontal",
        position: "top",
        total: { enabled: true }
      }
    }
  },
  dataLabels: { enabled: true },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"]
  },
  xaxis: {
    title: { text: "Date" },
    categories: []
  },
  yaxis: {
    title: { text: "Counts" },
    stepSize: 5
  },
  fill: { opacity: 1 },
  tooltip: {
    y: {
      formatter(val) {
        return val;
      }
    }
  }
};
