"use client";

import { useEffect, useState } from "react";
import { queryCloseApi } from "@utils/apiResolvers";
import formatDate from "@utils/formateDateTime";
import toast from "react-hot-toast";
import { barChartDefaultOptions } from "@constants/chartConfigs/barChart";
import AnalyticsChart from "../common/analyticsChart";

function DeliveriesAndPickupAnalytics() {
  const [deliveryDateFilter, setDeliveryDateFilter] = useState();
  const [deliveryPickupData, setDeliveryPickupData] = useState({ // Initialize the state with graph settings
    series: [
      {
        name: "Deliveries",
        data: []
      },
      {
        name: "Pickups",
        data: []
      }
    ],
    options: { ...barChartDefaultOptions }
  });

  const getDeliveryAndPickUpData = async (date) => {
    let payload = {};
    if (date) {
      payload = {
        dateTimeRange: {
          startDateTime: date[0],
          endDateTime: date[1]
        }
      };
    } else {
      payload = null;
    }
    try {
      const deliveryPickUpAndExchangeResponse = await queryCloseApi({
        queryTemplate: "get_deliveries_pickups_per_day",
        queryJsonParams: payload !== null ? payload : {}
      });

      if (deliveryPickUpAndExchangeResponse?.data) {
        const { data } = deliveryPickUpAndExchangeResponse;

        setDeliveryPickupData((prevState) => ({
          ...prevState,
          series: [
            {
              name: "Deliveries",
              data: data[0]?.data.map((item) => parseInt(item.count, 10))
            },
            {
              name: "Pickups",
              data: data[1]?.data.map((item) => parseInt(item.count, 10))
            }
          ],
          options: {
            ...prevState.options,
            xaxis: {
              ...prevState.options.xaxis,
              categories: data[0]?.data?.map((item) => formatDate(item.date, "dd-mm-yy"))
            }
          }
        }));
      }
    } catch (error) {
      toast.error("Failed to fetch data");
    }
  };

  const handleDeliveryAndPickupDateChange = (date) => {
    setDeliveryDateFilter(date);
    getDeliveryAndPickUpData(date);
  };

  useEffect(() => {
    getDeliveryAndPickUpData();
  }, []);

  return (
    <div>
      <AnalyticsChart
        graphName="Delivery And Pickup Count"
        data={deliveryPickupData}
        dateFilter={deliveryDateFilter}
        setDateFilter={handleDeliveryAndPickupDateChange}
      />
    </div>
  );
}

export default DeliveriesAndPickupAnalytics;
