"use client";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import formatDate from "@utils/formateDateTime";
import { queryCloseApi } from "@utils/apiResolvers";
import { barChartDefaultOptions } from "@constants/chartConfigs/barChart";
import AnalyticsChart from "../common/analyticsChart";

function AssetAnalytics() {
  const [laptopCountDateFilter, setLaptopCountDateFilter] = useState();
  const [laptopData, setLaptopData] = useState({ // Initialize the state with graph settings
    series: [
      {
        name: "Deliveries",
        data: []
      },
      {
        name: "Pickups",
        data: []
      }
    ],
    options: { ...barChartDefaultOptions }
  });

  const getLaptopCountData = async (date) => {
    let payload = {};
    if (date) {
      payload = {
        dateTimeRange: {
          startDateTime: date[0],
          endDateTime: date[1]
        }
      };
    } else {
      payload = null;
    }

    try {
      const LaptopCountResponse = await queryCloseApi({
        queryTemplate: "get_laptop_count_by_status_per_day",
        queryJsonParams: payload !== null ? payload : {}
      });

      if (LaptopCountResponse?.data) {
        const { data } = LaptopCountResponse;
        // Create a unique set of all dates
        const allDates = Array.from(new Set(data.flatMap((item) => item.data.map((i) => i.date)))).sort();

        // Map the series data to the correct dates
        const series = data.map((item) => ({
          name: item.name,
          data: allDates.map((d) => {
            const foundItem = item.data.find((i) => i.date === d);
            return foundItem ? parseInt(foundItem.count, 10) : 0;
          })
        }));

        setLaptopData((prevState) => ({
          ...prevState,
          series,
          options: {
            ...prevState.options,
            xaxis: {
              ...prevState.options.xaxis,
              categories: Array.from(new Set(data.flatMap((item) => item?.data.map((i) => formatDate(i.date, "dd-mm-yy")))))
            }
          }
        }));
      }
    } catch (error) {
      toast.error("Failed to fetch data");
    }
  };

  const handleLaptopCountDateChange = (date) => {
    setLaptopCountDateFilter(date);
    getLaptopCountData(date);
  };

  useEffect(() => {
    getLaptopCountData();
  }, []);

  return (
    <div>
      <AnalyticsChart
        graphName="Laptop Count"
        data={laptopData}
        dateFilter={laptopCountDateFilter}
        setDateFilter={handleLaptopCountDateChange}
      />
    </div>
  );
}

export default AssetAnalytics;
