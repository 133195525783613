import dynamic from "next/dynamic";
import Typography from "@mui/material/Typography";
import { DatePicker } from "antd";

const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });

const { RangePicker } = DatePicker;

function AnalyticsChart({
  graphName,
  data,
  dateFilter,
  setDateFilter,
  chartType = "bar",
  chartHeight = 350,
  rangePickerProps = {},
  chartOptions = {}
}) {
  return (
    <div>
      <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
        <Typography variant="h6">{graphName}</Typography>
        <RangePicker
          style={{ width: "30%", height: "38px" }}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          placeholder={["Start Date", "End Date"]}
          onChange={(date) => setDateFilter(date)}
          value={[dateFilter?.[0], dateFilter?.[1]]}
          showTime
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rangePickerProps}
        />
      </div>
      <div>
        <Chart
          options={{
            ...data.options,
            ...chartOptions
          }}
          series={data.series}
          type={chartType}
          height={chartHeight}
        />
      </div>
    </div>
  );
}

export default AnalyticsChart;
