"use client";

import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import toast from "react-hot-toast";
import { queryCloseApi } from "@utils/apiResolvers";
import DeliveryPickupExchangeSummeryCard from "./DeliveryPickupExchangeSummaryCard";

export default function DeliveryPickupExchangeSummery() {
  const [data, setData] = React.useState();

  const fetchData = async () => {
    try {
      const response = await queryCloseApi({ queryTemplate: "get_delivery_exchange_pickup_earnings", queryJsonParams: {} });
      if (response?.data) {
        setData(response.data);
      }
    } catch (error) {
      toast.error("Error fetching data");
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ margin: 10 }}>
      <Typography marginBottom={3} variant="h6">Earnings Per Delivery Person</Typography>
      <Grid container spacing={2}>
        {data && data?.map((person) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={person.nameOfInternalUser + person.month}>
            <DeliveryPickupExchangeSummeryCard
              deliveryAmount={person.deliveryEarnings}
              pickUpAmount={person.pickupEarnings}
              exchangeAmount={person.exchangeEarnings}
              nameOfInternalUser={person.nameOfInternalUser}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
