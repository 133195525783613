import * as React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

export default function DeliveryPickupExchangeSummeryCard({ nameOfInternalUser, deliveryAmount, pickUpAmount, exchangeAmount }) {
  return (
    <Card variant="outlined" sx={{ maxWidth: 220 }}>
      <Box sx={{ px: 2, py: 1 }}>
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle2" component="div">
            {nameOfInternalUser}
          </Typography>
          <Typography variant="caption" component="div">
            {`${new Date().toLocaleString("en-us", { month: "long" })} ${new Date().getFullYear()}`}
          </Typography>
        </Stack>
      </Box>
      <Divider />
      <Box sx={{ px: 2, pt: 1 }}>
        <Stack direction="column" spacing={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2" component="div">
              Delivery :
            </Typography>
            <Typography variant="body2" component="div">
              {deliveryAmount}
              /-
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2" component="div">
              Pickup :
            </Typography>
            <Typography variant="body2" component="div">
              {pickUpAmount}
              /-
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2" component="div">
              Exchange :
            </Typography>
            <Typography variant="body2" component="div">
              {exchangeAmount}
              /-
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <Box sx={{ px: 2, py: 1 }}>
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body1" component="div">
            Total :
          </Typography>
          <Typography color="green" variant="body1" component="div">
            {deliveryAmount + pickUpAmount + exchangeAmount}
          </Typography>
        </Stack>
      </Box>
    </Card>
  );
}
