"use client";

import { useEffect, useState } from "react";
import { queryCloseApi } from "@utils/apiResolvers";
import formatDate from "@utils/formateDateTime";
import toast from "react-hot-toast";
import { barChartDefaultOptions } from "@constants/chartConfigs/barChart";
import AnalyticsChart from "../common/analyticsChart";

function ExchangeAnalytics() {
  const [exchangeDateFilter, setExchangeDateFilter] = useState();
  const [exchangeData, setExchangeData] = useState({ // Initialize the state with graph settings
    series: [
      {
        name: "Exchanges",
        data: []
      }
    ],
    options: { ...barChartDefaultOptions }
  });

  const fetchExchangeData = async (date) => {
    let payload = {};
    if (date) {
      payload = {
        dateTimeRange: {
          startDateTime: date[0],
          endDate: date[1]
        }
      };
    } else {
      payload = null;
    }

    try {
      const ExchangeResponse = await queryCloseApi({
        queryTemplate: "get_exchanges_per_day",
        queryJsonParams: payload !== null ? payload : {}
      });

      if (ExchangeResponse?.data) {
        const { data } = ExchangeResponse;

        setExchangeData((prevState) => ({
          ...prevState,
          series: [
            {
              name: "Exchange",
              data: data?.data.map((item) => parseInt(item.count, 10))
            }
          ],
          options: {
            ...prevState.options,
            xaxis: {
              ...prevState.options.xaxis,
              categories: data?.data?.map((item) => formatDate(item.date, "dd-mm-yy"))
            }
          }
        }));
      }
    } catch (error) {
      toast.error("Failed to fetch data");
    }
  };

  const handleExchangeDateChange = (date) => {
    setExchangeDateFilter(date);
    fetchExchangeData(date);
  };

  useEffect(() => {
    fetchExchangeData();
  }, []);

  return (
    <div>
      <AnalyticsChart
        graphName="Exchange Count"
        data={exchangeData}
        dateFilter={exchangeDateFilter}
        setDateFilter={handleExchangeDateChange}
      />
    </div>
  );
}

export default ExchangeAnalytics;
